<template>
	<main class="container">

		<div class="content">

			<div class="content_head" style="margin-bottom: 30px; padding-bottom: 0">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>분석 보고서</li>
						</ul>
					</div>
					<h2 class="page_title">분석 보고서</h2>

					<div v-if="!this.isLogin() || this.getSessionProc().myService==undefined || this.getSessionProc().myService == null" class="account_info" style="margin-top: 30px">
						<b></b> 샘플 페이지 입니다.
					</div>
				</div>
			</div>
			<div class="inner">
				<div class="content_body" width="100%"  height="0" style="position: relative; padding: 56% 10% 0; margin-bottom: 0">
					<iframe width="100%" height="100%" :src="analLink" style="position: absolute; top: 0; left: 1%;"
									allowfullscreen></iframe>
				</div>
					<div class="" style="margin-top: 10px; display: flex; justify-content: space-between;">
						<b>▲ > 버튼을 누르면 메뉴가 확대됩니다.</b>
						<b>렌즈버튼을 누르면 레포트가 확대됩니다 ▲</b>
					</div>
				</div>
			</div>
	</main>
</template>
<script>
export default {
	name: "analysis",
	data() {
		return {
			analLink: ''
		}
	},
	mounted() {

		if (!this.isLogin()) {
			//로그인 정보 없을 때 샘플 페이지 보이기
			this.analLink = 'https://lookerstudio.google.com/embed/reporting/8cea0eb8-3de6-4f69-88cf-7515d746dfea/page/romTD';
		} else {
			this.getUrl()
		}

	},

	methods: {
		getUrl() {
			const self = this;

			if(!this.getSessionProc().isGuest) {

				if(self.getSessionProc().myService==undefined | self.getSessionProc().myService == null) {
					self.analLink = 'https://lookerstudio.google.com/embed/reporting/8cea0eb8-3de6-4f69-88cf-7515d746dfea/page/romTD';
				}else{
					self.analLink = self.getSessionProc().myService.analysisUrl;
				}

			} else {

				if(self.getSessionProc().guestInfo==undefined | self.getSessionProc().guestInfo == null) {
					this.swalUtils.gritter("접근이 불가능한 서비스입니다.", "", "info", 3000)
						.then(() => {
							location.href='/';
						})
				}

				self.analLink = self.getSessionProc().guestInfo.analysisUrl;
			}

		}
	}
}
</script>

<style scoped>

</style>